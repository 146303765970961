const getDataTestFromProduct = (product) => {

  const result = {
    product_id: product.id,
    title: product.title,
    handle: product.handle,
    description: product.descriptionHtml,
    variants: product.variants.edges.map(({ node }) => ({
      id: node.id,
      compare_at_price: node.compareAtPrice,
      title: node.title,
      price: node.price,
      image_id: node.image?.id || null,
    })),
    images: product.images.edges.map(({ node }, index) => ({
      id: node.id,
      product_id: product.id,
      src: node.src,
      position: index,
      variant_ids: product.variants.edges
        .filter(({ node: variant }) => variant.image?.id === node.id)
        .map(({ node: variant }) => variant.id),
      alt: node.altText || null,
    })),
  }
  return result
}

export default getDataTestFromProduct

//  const getDataTestFromProduct = (product) => {
//   const result = {
//     product_id: product.id,
//     title: product.title,
//     handle: product.handle,
//     description: product.descriptionHtml,
//     variants: product.variants.map(_v => ({
//       id: _v.id,
//       compare_at_price: _v.compare_at_price,
//       title: _v.title,
//       price: _v.price,
//       image_id: _v.image_id,
//     })),
//     images: product.images.map(shopifyImage => ({
//       id: shopifyImage.id,
//       product_id: shopifyImage.product_id,
//       src: shopifyImage.src,
//       position: shopifyImage.position,
//       variant_ids: shopifyImage.variant_ids,
//       alt: shopifyImage.alt
//     })),
//   }
//   return result
// }

// export default getDataTestFromProduct
