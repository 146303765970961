import { Page, Grid, Button, Card, Spinner, Stack } from '@shopify/polaris'
import useCustomHistory from '../../hooks/useCustomHistory'
import { useTestsAB } from '../Dashboard/hooks'
import { useEffect } from 'react'

export default function Welcome({ shop }) {
  const [{ appExtension, loaded }, { getTests }] = useTestsAB()
  useEffect(() => {
    getTests(1, '')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const history = useCustomHistory()
  function showExtensionLock(app) {
    if (shop.isDevelopment) return false
    return !app.loaded
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ minHeight: '80vh' }}>
        {loaded && (
          <Page>
            <div>
              <Button onClick={() => history.push('/')}>Back</Button>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '20px 0 10px 0' }}>
                <p style={{ fontSize: '28px', fontWeight: '600' }}>Welcome</p>
                Which kind of test would you like to create? Don’t worry, you can always create more tests later.
              </div>
            </div>
            {/* { showExtensionLock(appExtension) && (
              <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.4)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 999 }}>
                <div style={{ textAlign: 'center', backgroundColor: 'white', padding:'2rem', borderRadius: '1rem', display: 'flex' }}>
                  <div style={{ margin: 'auto' }}>
                    <img
                      alt=""
                      style={{ objectFit: 'cover', objectPosition: 'center', width: '100px', height: '100px' }}
                      src="/LockFilledIcon.svg"
                    />
                    <div style={{ marginTop: '10px', fontSize: '18px', fontWeight: 600 }}>
                      You have to enable app extension to create tests
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <Stack vertical spacing="loose">
                        <Button primary external url={ `https://${shop.shopify_domain}/admin/themes/current/editor?context=apps&uuid=${appExtension.uuid}` }>Enable App Extension</Button>
                        <Stack vertical={false} distribution="center">
                          <Button info onClick={() => {history.push('/')}}> Back to dashboard</Button>
                          <Button info onClick={() => {getTests(1)}}>Refresh</Button>
                        </Stack>
                      </Stack>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            <Grid>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <Card>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '20px', rowGap: '10px' }}>
                    <img
                      alt=""
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      src="/Illustration-2.svg"
                    />
                    <div style={{ fontSize: '17px', fontWeight: 600, lineHeight: '32px' }}>
                      Product Attribute Test
                    </div>
                    <div style={{ padding: '0 .75rem', textAlign: 'center' }}>
                      Test different titles, prices, descriptions and even images!
                    </div>
                    <div>
                      <Button primary onClick={() => history.push('/test')}>Create Test</Button>
                    </div>
                  </div>
                </Card>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <Card>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '20px', rowGap: '10px' }}>
                    <img
                      alt=""
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      src="/Illustration.svg"
                    />
                    <div style={{ fontSize: '17px', fontWeight: 600, lineHeight: '32px' }}>
                      Bulk Product Price Test
                    </div>
                    <div style={{ padding: '0 .75rem', textAlign: 'center' }}>
                      Optimize the price of multiple products all at once.
                    </div>
                    <div>
                      <Button primary onClick={() => history.push('/bulk-test')}>Create Test</Button>
                    </div>
                  </div>
                </Card>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <Card>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '20px', rowGap: '10px' }}>
                    <img
                      alt=""
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      src="/Illustration.svg"
                    />
                    <div style={{ fontSize: '17px', fontWeight: 600, lineHeight: '32px' }}>
                      Content Test
                    </div>
                    <div style={{ padding: '0 .75rem', textAlign: 'center' }}>
                      Test different layouts, texts and images!
                    </div>
                    <div>
                      <Button primary onClick={() => history.push('/content-test-form')}>Create Test</Button>
                    </div>
                  </div>
                </Card>
              </Grid.Cell>
            </Grid>
          </Page>
        )}
        {!loaded &&
          <Page>
            <div className="spinner-container">
              <Spinner />
            </div>
          </Page>
        }
      </div>
    </div>
  )
}

