import { Card, Label, Link } from '@shopify/polaris'
import React from 'react'
import { Field, formValueSelector, getFormValues } from 'redux-form'
import { addValidator, required } from 'redux-form-validators'
import SelectInput from '../../../../components/Fields/SelectInput'
import DateTime from './components/DateTime/DateTime'
import './style.scss'
import moment from 'moment'
import { useSelector } from 'react-redux'
import TextInput from '../../../../components/Fields/TextInput'
import CheckBoxInput from '../../../../components/Fields/CheckBoxInput'
import { arePricesChanged } from '../../../../lib/arePricesChanged'
import AutomaticWinnerSection from './components/AutomaticWinner/AutomaticWinnerSection'
import SupportLink from '../../../../lib/SupportLink'
import DontEditWarningSection from './components/DontEditWarning/DontEditWarningSection'

const dateFinish = addValidator({
  validator: function(options, value, allValues) {
    if (value) {
      if (moment(value) < moment(allValues.start_date)) {
        return {
          defaultMessage: 'Finish date/time should be greater than start date/time',
        }
      }
    }
  },
})

const timePlan = addValidator({
  validator: function(options, value, allValues) {
    const { isChangePrice } = options
    if (value && options.minutes) {

      let time = value

      if (allValues.variant_every_type === 'days') {
        time = parseInt(value) * 24 * 60
      }

      if (allValues.variant_every_type === 'hours') {
        time = parseInt(value) * 60
      }

      if (time < options.minutes) {
        return {
          defaultMessage: isChangePrice
            ? 'Because you\'re testing different prices on your variants, we\'ve set the minimum frequency of this test to 60 minutes. Please enter a frequency of 60 minutes or more.'
            : `Please enter a value of ${options.minutes} minutes or more.`,
        }
      }
    }
  },
})

const getSwitches = (formValues) => {
  let date = (moment(formValues.finish_date).toDate() - moment(formValues.start_date).toDate()) / 60000

  if (moment(formValues.start_date) < moment() ) {
    date = (moment(formValues.finish_date).toDate() - moment().toDate()) / 60000
  }

  let res = 0

  if (formValues.variant_every_type === 'days') {
    res = Math.round(date/(formValues.variant_every * 24 * 60))
  } else if (formValues.variant_every_type === 'hours') {
    res = Math.round(date/(formValues.variant_every * 60))
  } else {
    res = Math.round(date/formValues.variant_every)
  }

  return res > 0 ? res : 0
}

function FormStep3() {
  const selector = formValueSelector('test_a_b')
  // const finish_date = useSelector(state => selector(state, 'finish_date'))
  const shop = useSelector(state => state.shop.shop)
  const start_date = useSelector(state => selector(state, 'start_date'))
  const formValues = useSelector(state => getFormValues('test_a_b')(state))
  const arePricesChangedConst = arePricesChanged(formValues.test_a, formValues.test_b)

  return (
    <div className="FormStep3">
      <div className="text-content">
        <div className="title">Choose period for the test</div>
        <p>Set the timeframe during which you’d like to run your test. You can set exact dates and times or start it right now if you’d like.</p>
        <p>You’ll also want to select how often TridentAB will switch your variants from A to B (from your original product listing to the one you’ve modified).</p>
        <p>Note: We recommend running the test for at least 2 weeks to get enough data points, but do consider traffic to your site as well when setting the timeframe.</p>
      </div>
      <div className="form-content">
        <Card title="Adjust the timeframe" sectioned>
          <div className="row">
            <Field
              component={DateTime}
              label1="Start Date"
              label2="Start Time"
              name="start_date"
              disableDatesBefore={moment().add(-1, 'days').toDate()}
            />
            <Field
              component={DateTime}
              name="finish_date"
              label1="Finish Date"
              label2="Finish Time"
              disableDatesBefore={moment(start_date).add(-1, 'days').toDate()}
              validate={[dateFinish()]}
            />
          </div>
        </Card>
        <Card>
          <Card.Section>
            <div className="row block2">
              <div className="col-2 col-tmp">
                <div className="Switches">
                  <div className="title">Switches between A & B</div>
                  <div className="text">{getSwitches(formValues)}</div>
                </div>
              </div>
              <div className="col-2 col-tmp">
                <div style={{ marginBottom: 5 }}>
                  <Label>Change variants every:</Label>
                </div>
                <div className="row">
                  <div className="col-2">
                    <Field
                      component={TextInput}
                      type="number"
                      name="variant_every"
                      // options={[
                      //   { label: '15 minutes', value: '15' },
                      //   { label: '30 minutes', value: '30' },
                      //   { label: '45 minutes', value: '45' },
                      // ]}
                      label=""
                      validate={[required(), timePlan({ minutes: arePricesChangedConst ? 60 : shop?.pricingPlanData?.intervalLimit, arePricesChangedConst })]}
                    />
                  </div>
                  <div className="col-2">
                    <Field
                      component={SelectInput}
                      name="variant_every_type"
                      options={[
                        { label: 'minutes', value: 'minutes' },
                        { label: 'hours', value: 'hours' },
                        { label: 'days', value: 'days' },
                      ]}
                      label=""
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card.Section>
        </Card>
        <DontEditWarningSection></DontEditWarningSection>
        <AutomaticWinnerSection></AutomaticWinnerSection>
        {arePricesChangedConst && <Card title="Price change warning">
          <div className="warning-price">
            <p>Please note that by testing different prices on your product, it is possible that a "Switch" between variants happens at the same time as one of your visitors has this product in their cart.</p>
            <p>In order to mitigate this risk, we do not recommend setting the frequency to less than 60 minutes. As a result, we've set 60 minutes as the minimum frequency for this test. By doing so, we'll be minimizing the risk that this issue occurs.</p>
            <p style={{ marginBottom: 20 }}>If this happens, your visitor may end up checking out at a different price than what they saw initially when adding the product to their cart. Use this function carefully and only for the duration needed to identify your optimal pricing. Feel free to <Link external url={ new SupportLink().getSupport(shop) }>reach out to our support team</Link> if you have any questions 🙌​.</p>
            <Field
              component={CheckBoxInput}
              label="I understand and would like to test a price change"
              name="agree"
              validate={[required({ message: 'Please confirm that you understand this warning.' })]}
            />
          </div>
        </Card>}
      </div>
    </div>
  )
}

export default FormStep3
