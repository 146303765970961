import { Page, Grid, Card, Checkbox, TextField, Select, Link } from '@shopify/polaris'
import React, { useState, useCallback, useEffect } from 'react'
import MomentUtils from '@date-io/moment'
import { ThemeProvider } from '@material-ui/styles'
import moment from 'moment'
import { createTheme } from '@material-ui/core/styles'
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import SupportLink from '../../../../lib/SupportLink'

const theme = createTheme({
  palette: {
    primary: {
      light: '#3f50b5',
      main: '#108043',
      dark: '#757ce8',
      contrastText: '#fff',
    },
    secondary: {
      light: '#002884',
      main: '#ff7961',
      dark: '#f44336',
      contrastText: '#000',
    },
  },
})

export default function Step3({ formData, setFormData, validationErrors, setValidationErrors, shop }) {

  const [startDate, setStartDateChange] = useState(moment(formData.start_date))
  const [finishDate, setFinishDateChange] = useState(moment(formData.finish_date))
  const [variantEvery, setVariantEvery] = useState(formData.variant_every)
  const [variantEveryType, setVariantEveryType] = useState(formData.variant_every_type)
  const [switchCount, setSwitchCount] = useState()
  const [priceWarningAgreement, setPriceWarning] = useState(false)

  const handleStartDateChange = useCallback((newStartDate) => {
    setStartDateChange(newStartDate)
    setFormData({ ...formData, 'start_date': newStartDate })

    if (moment(newStartDate) > moment(finishDate)) {
      setValidationErrors({ ...validationErrors, datetime: true })
    } else {
      setValidationErrors({ ...validationErrors, datetime: false })
    }

  }, [formData, setFormData, setValidationErrors, validationErrors, finishDate])

  const handleFinishDateChange = useCallback((newFinishDate) => {
    setFinishDateChange(newFinishDate)
    setFormData({ ...formData, 'finish_date': newFinishDate })

    if (moment(startDate) > moment(newFinishDate)) {
      setValidationErrors({ ...validationErrors, datetime: true })
    } else {
      setValidationErrors({ ...validationErrors, datetime: false })
    }

  }, [formData, setFormData, setValidationErrors, validationErrors, startDate])

  const handleChangeVariantEvery = useCallback((newVariantEvery) => {
    setVariantEvery(newVariantEvery)
    setFormData({ ...formData, 'variant_every': parseInt(newVariantEvery) })

  }, [formData, setFormData])

  const handleEveryTypeChange = useCallback((newVariantEveryType) => {
    setVariantEveryType(newVariantEveryType)
    const minValues = {
      minutes: shop?.pricingPlanData?.intervalLimit,
      hours: 1,
      days: 1
    }
    setVariantEvery(minValues[newVariantEveryType])
    setFormData({ ...formData, 'variant_every_type': newVariantEveryType })
  }, [formData, setFormData, shop])

  const options = [
    { label: 'Minutes', value: 'minutes' },
    { label: 'Hours', value: 'hours' },
    { label: 'Days', value: 'days' },
  ]

  useEffect(() => {
    function countSwitchs() {
      let date = (moment(finishDate).toDate() - moment(startDate).toDate()) / 60000
      if (moment(startDate) < moment()) {
        date = (moment(finishDate).toDate() - moment().toDate()) / 60000
      }
      let res = 0
      if (variantEveryType === 'days') {
        res = Math.round(date / (variantEvery * 24 * 60))
      } else if (variantEveryType === 'hours') {
        res = Math.round(date / (variantEvery * 60))
      } else {
        res = Math.round(date / variantEvery)
      }
      const switchs = res > 0 ? res : 0
      return switchs
    }
    const switchs = countSwitchs()
    setSwitchCount(switchs)

  }, [startDate, finishDate, variantEvery, variantEveryType, validationErrors, setValidationErrors])

  return (
    <Page fullWidth>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 3 }}>
            <div style={{ padding: '0 .75rem' }}>
              <div style={{ fontSize: '28px', fontWeight: 600, lineHeight: '32px', marginBottom: '20px' }}>
                Choose period for the test
              </div>
              <div style={{ display: 'flex', fontSize: '14px', flexDirection: 'column', rowGap: '30px' }}>
                <p>Set the timeframe during which you’d like to run your test. You can set exact dates and times or start it right now if you’d like. </p>
                <p> You’ll also want to select how often TridentAB will switch your variants from A to B (from your original product listing to the one you’ve modified).</p>
                <p> Note: We recommend running the test for at least 2 weeks to get enough data points, but do consider traffic to your site as well when setting the timeframe.</p>
              </div>
            </div>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 9 }}>
            <Card title="Adjust the timeframe">
              <Card.Section>
                <ThemeProvider theme={theme}>
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
                    <div>
                      <h1>Start Date and Time</h1>
                      <DateTimePicker value={startDate}
                        onChange={handleStartDateChange}
                        showTodayButton={true}
                        autoOk
                        InputProps={{
                          style: {
                            fontSize: 16,
                            height: 40
                          }
                        }}
                        inputVariant="outlined"
                        style={{ width: '100%' }}
                        disablePast />
                    </div>
                    <div>
                      <h1>Finish Date and Time</h1>
                      <DateTimePicker value={finishDate} strictCompareDates={true} onChange={handleFinishDateChange}
                        autoOk
                        inputVariant="outlined"
                        showTodayButton={true}
                        disablePast
                        InputProps={{
                          style: {
                            fontSize: 16,
                            height: 40
                          }
                        }}
                        minDate={startDate}
                        style={{ width: '100%' }}
                        initialFocusedDate={moment(startDate).add(1, 'days')}
                        minDateMessage={'The finish date should not be before the start date '}
                      />
                    </div>
                  </div>
                </ThemeProvider>
              </Card.Section>
            </Card>
            <Card>
              <div style={{ display: 'flex', padding: '30px', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', columnGap: '1px' }}>
                  <div style={{ fontSize: '20px', fontWeight: 600, lineHeight: '32px', marginBottom: '20px' }}>
                    Switches between A & B
                  </div>
                  <div style={{ fontSize: '48px', fontWeight: 600, lineHeight: '32px', marginBottom: '20px' }}>{switchCount}</div>
                </div>
                <div>
                  <TextField
                    label="Change variants every:"
                    value={variantEvery}
                    min={variantEveryType === 'minutes' ? shop?.pricingPlanData?.intervalLimit : 1}
                    type="number"
                    onChange={handleChangeVariantEvery}
                    autoComplete="off"
                    connectedRight={
                      <Select
                        options={options}
                        onChange={handleEveryTypeChange}
                        value={variantEveryType}
                      />
                    }
                  />
                </div>
              </div>
            </Card>
            <Card title="Price change warning">
              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px', padding: '20px' }}>
                <p>Please note that by testing different prices on your product, it is possible that a "Switch" between variants happens at the same time as one of your visitors has this product in their cart.</p>
                <p>In order to mitigate this risk, we do not recommend setting the frequency to less than 60 minutes. As a result, we've set 60 minutes as the minimum frequency for this test. By doing so, we'll be minimizing the risk that this issue occurs.</p>
                <p>If this happens, your visitor may end up checking out at a different price than what they saw initially when adding the product to their cart. Use this function carefully and only for the duration needed to identify your optimal pricing. Feel free to <Link external url={ new SupportLink().getSupport(shop) }>reach out to our support team</Link> if you have any questions 🙌​.</p>
                <Checkbox
                  label="I understand and would like to test a price change"
                  checked={priceWarningAgreement}
                  error={validationErrors.priceWarningAgreement ? ' Please confirm that you understand this warning.' : null}
                  onChange={() => {
                    setFormData({ ...formData, 'priceWarningAgreement': !priceWarningAgreement })
                    setPriceWarning(!priceWarningAgreement)
                    setValidationErrors({ ...validationErrors, priceWarningAgreement: false })
                  }}
                />
              </div>
            </Card>
          </Grid.Cell>
        </Grid>
      </MuiPickersUtilsProvider>
    </Page >
  )
}
